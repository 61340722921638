import React from 'react';
import { View, StyleSheet, Text } from 'react-native';

const DataTableCell = ({ children, style, width = 1, ...props }) => {
  return (
    <View style={[styles.cell, { flex: width }, style]} {...props}>
      <Text>{children}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  cell: {
    justifyContent: 'center',
    paddingRight: 5,
  },
});

export default DataTableCell;