import { baseApi, PagedTableDto } from './base-api'

export const ticketApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getticket: build.query<any, any>({
      query: (id) => ({
        url: `tickets/getticket/${id}`,
      }),
    }),
    
    getTicketByAsset: build.query<any, any>({
      query: (assetId) => ({
        url: `tickets/getticketbyasset/${assetId}`,
      }),
    }),
    exportTickets: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `tickets/exporttickets?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
    }),
    getTickets: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `tickets/gettickets?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    getUpdates: build.query<any, PagedTableDto>({
      query: (dto) => ({
        url: `updates/getupdates?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
      }),
      transformResponse: (response: any, meta, arg) => {
        var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

        return {
          list: response,
          pagination: pagination
        };
      }
    }),
    addTicket: build.mutation<any, any>({
      query: (body) => ({
        url: 'tickets/addTicket',
        method: 'post',
        body: body
      }),
    }),
    addUpdate: build.mutation<any, any>({
      query: (body) => ({
        url: 'updates/addupdate',
        method: 'post',
        body: body
      }),
    }),
    updateTicketStatus: build.mutation<any, { id: string, status: string }>({
      query: ({ id, status }) => ({
        url: `tickets/updateticketstatus/${id}`,
        method: 'put',
        body: { status }
      }),
    }),
    updateTicketEmail: build.mutation<any, { id: string, contactEmail: string }>({
      query: ({ id, contactEmail }) => ({
        url: `tickets/updateticketemail/${id}`,
        method: 'put',
        body: { contactEmail }
      }),
    }),
    getTicketsByAsset: build.query<any, any>({
      query: (id) => ({
        url: `tickets/getticketsbyasset/${id}`,
      }),
    }),
    getOpenTicketsByAsset: build.query<any, any>({
      query: (id) => ({
        url: `tickets/getopenticketsbyasset/${id}`,
      }),
    }),
  }),
  overrideExisting: true,
})

export const {
  useGetTicketsQuery,
  useUpdateTicketStatusMutation,
  useUpdateTicketEmailMutation,
  useAddUpdateMutation,
  useGetUpdatesQuery,
  useAddTicketMutation,
  useGetTicketByAssetQuery,
  useGetTicketsByAssetQuery,
  useGetOpenTicketsByAssetQuery,
  useExportTicketsQuery
} = ticketApi