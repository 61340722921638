import React, { useEffect, useState } from "react";
import { useGetAllAssetTypesQuery, useGetAllSubAssetTypesQuery } from "../../../../store/services/api/assetType-api";
import { useGetAllBuildingsQuery, useGetAllFloorsQuery, useGetAllLocationsQuery } from "../../../../store/services/api/building-api";
import { FlatList, SafeAreaView, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from "react-native";
import { EvilIcons } from '@expo/vector-icons';
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import { Controller, useForm } from "react-hook-form";
import { Picker } from "@react-native-picker/picker";
import { LoadingIndicator } from "../../../web/components/LoadingIndicator";
import { assetApi } from "../../../../store/services/api/asset-api";
import { PagedTableDto } from "../../../../store/services/api/base-api";
import { DataTable } from "react-native-paper";
import { DataTableHeader } from "../../../web/components/DataTableHeader";
import { INT32_MAX_VALUE } from "../../../../utils/constants";

const AssetFilters = ({ onAssetsSearched, show }) => {
  const { data: buildings } = useGetAllBuildingsQuery(null);
  const { data: floors } = useGetAllFloorsQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: assetTypes } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes } = useGetAllSubAssetTypesQuery(null);

  const [page, setPage] = React.useState<number>(0);
  const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(INT32_MAX_VALUE);
  const [sortedColumn, setSortedColumn] = React.useState<string>("code");
  const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);

  const [loading, setLoading] = useState(false);

  const [trigger] = assetApi.useLazyGetAssetsQuery();

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      assetTypeId: "",
      subAssetTypeId: "",
      buildingId: "",
      floorId: "",
      locationId: "",
      code: "",
    }
  });

  let buildingId = watch("buildingId");
  let floorId = watch("floorId");
  let assetTypeId = watch("assetTypeId");

  useEffect(() => {
    if (buildings && floors && locations && assetTypes && subAssetTypes) {
      setLoading(false);
    }
  }, [buildings, floors, locations, assetTypes, subAssetTypes]);

  const onSubmit = async (data) => {
    const hasValue = Object.values(data).some(value => value !== "");
    if (!hasValue) {
      alert("Please select or enter at least one value before searching.");
      return;
    }

    var dto = new PagedTableDto(page, {
      buildingId: data.buildingId,
      floorId: data.floorId,
      assetTypeId: data.assetTypeId,
      subAssetTypeId: data.subAssetTypeId,
      code: data.code,
      pageSize: numberOfItemsPerPage
    }, sortedColumn, sortedColumnAscending);

    var response = await trigger(dto);
    if (response.isError) {
      alert("There was an error fetching assets. Please try again");
      return;
    }

    onAssetsSearched(response.data);

    setLoading(false);
  }

  if (loading) {
    return <LoadingIndicator />
  }

  // Passing in a show variable because we want to retain the state, so instead
  // of wrapping the entire component in a conditional we are doing it this way.
  if (!show) return null;

  return (
    <ScrollView contentContainerStyle={styles.scrollViewContent}>
      <View style={styles.section}>
        <Controller
          control={control}
          name="assetTypeId"
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={{ flexDirection: 'row', }}>
              <Text style={styles.title}>Asset Type (*)</Text>
              <Picker style={styles.input}
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                {assetTypes != null && assetTypes?.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>
          )}
        />
      </View>

      <View style={styles.section}>
        <Controller
          control={control}
          name="subAssetTypeId"
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.title}>Sub Asset Type (*)</Text>
              <Picker style={styles.input}
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                {assetTypeId != null && subAssetTypes?.filter(sa => sa.assetTypeId === assetTypeId).map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>
          )}
        />
      </View>

      <View style={styles.section}>
        <Controller
          control={control}
          name="buildingId"
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.title}>Building (*)</Text>
              <Picker style={styles.input}
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                {buildings != null && buildings?.map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>
          )}
        />
      </View>

      <View style={styles.section}>
        <Controller
          control={control}
          name="floorId"
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.title}>Floor (*)</Text>
              <Picker style={styles.input}
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                {buildingId && floors?.filter(f => f.buildingId === buildingId).map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>
          )}
        />
      </View>

      <View style={styles.section}>
        <Controller
          control={control}
          name="locationId"
          rules={{ required: false }}
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.title}>Location (*)</Text>
              <Picker style={styles.input}
                selectedValue={value}
                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                itemStyle={{ height: 50 }}>

                <Picker.Item label="Please Select" value="" />

                {floorId && locations?.filter(l => l.floorId === floorId).map((value, index) => {
                  return (
                    <Picker.Item key={value.id} label={value.name} value={value.id} />
                  )
                })}
              </Picker>
            </View>
          )}
        />
      </View>

      <View style={styles.section}>
        <Controller
          control={control}
          name="code"
          render={({ field: { onChange, onBlur, value } }) => (
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.title}>Code </Text>
              <TextInput
                style={styles.input}
                onBlur={onBlur}
                onChangeText={onChange}
                value={value}>
              </TextInput>
            </View>
          )}
        />
      </View>

      <View style={styles.section}>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={handleSubmit(onSubmit)}
          >
            <Text style={styles.buttonText}>Search asset</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  )
}

const AssetsList = ({ assetsTableData, onClear, onSaved, uniqueRef }) => {
  const [getAsset] = assetApi.useLazyGetAssetQuery();
  const [updateAsset] = assetApi.useUpdateAssetMutation();

  const [loading, setLoading] = useState(false);

  const updateUniqueRef = async (item) => {
    if (!confirm(`Are you sure you want to update this QR code to: ${uniqueRef}`)) {
      return;
    }

    setLoading(true);

    const assetResponse = await getAsset(item.id);
    if (assetResponse.isError) {
      alert("Error getting asset");
      setLoading(false);
      return;
    }
    const updatedAsset = {
      ...assetResponse.data,
      uniqueReference: uniqueRef
    };

    try {
      await updateAsset(updatedAsset);
    } catch (e) {
      alert("Error updating asset");
      setLoading(false);
    }

    onSaved(updatedAsset);
    setLoading(false);
  }

  if (loading) {
    return <LoadingIndicator />
  }

  const renderItem = ({ item }) => {
    return (
      <TouchableOpacity style={styles.assetItem} onPress={() => updateUniqueRef(item)}>
        <Text>{item.buildingName}</Text>
        <Text>{item.floorName}</Text>
        <Text>{item.locationName}</Text>
        <Text>{item.assetTypeName}</Text>
        <Text>{item.subAssetTypeName}</Text>
        <Text>{item.description}</Text>
        <Text>{item.code}</Text>
        <Text>{item.uniqueReference}</Text>
      </TouchableOpacity>
    )
  }

  return (
    <View style={styles.scrollViewContent}>
      <View style={styles.section}>
        <View style={styles.buttonContainer}>
          <TouchableOpacity style={styles.button} onPress={onClear}>
            <Text style={styles.buttonText}>Back to search</Text>
          </TouchableOpacity>
        </View>
      </View>

      <FlatList
        data={assetsTableData.list}
        renderItem={renderItem}
        keyExtractor={item => item.id}
        style={{ width: '90%' }}
      />
    </View>

  )
}

export default function AssetReplaceQRScreen({ navigation, route }) {
  const { uniqueRef } = route.params

  const [assetsTableData, setAssetsTableData] = useState(null);

  const onSaved = (asset) => {
    navigation.navigate("asset", { uniqueRef: asset.uniqueReference })
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity style={GlobalStyle.sub__title__icon} onPress={() => navigation.navigate("asset", { uniqueRef: uniqueRef })}>
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
      </View>

      <AssetFilters onAssetsSearched={(data) => setAssetsTableData(data)} show={assetsTableData == null} />
      {assetsTableData && <AssetsList assetsTableData={assetsTableData} onClear={() => setAssetsTableData(null)} onSaved={(asset) => onSaved(asset)} uniqueRef={uniqueRef} />}

    </SafeAreaView>
  )
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  scrollViewContent: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 10,
    alignSelf: "stretch",
  },
  title: {
    flex: 1,
    fontSize: 14,
    fontWeight: 'bold',
    marginVertical: 20,
    textAlign: 'center',
  },
  section: {
    width: "90%",
    height: "auto",
    alignItems: 'stretch',
    justifyContent: 'space-evenly'
  },
  input: {
    flex: 2,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 5,
    backgroundColor: "white",
    width: "100%",
    margin: 5,
  },
  buttonContainer: {
    marginVertical: 10,
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    alignSelf: 'center'
  },
  button: {
    backgroundColor: PRIMARY_COLOUR,
    padding: 8,
    borderRadius: 4,
    justifyContent: "center",
    width: '90%',
    alignSelf: 'center'
  },
  buttonText: {
    color: 'white',
    marginLeft: 10,
    alignSelf: 'center',
    fontSize: 20
  },
  assetItem: {
    width: '100%',
    borderBottomColor: '#CECECE',
    borderBottomWidth: 1,
    padding: 10,
    marginBottom: 5,
  }
});
