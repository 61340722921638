import { createNativeStackNavigator } from '@react-navigation/native-stack';
import AssetViewScreen from './AssetViewScreen';
import AssetDetailsScreen from './AssetDetailsScreen';
import AssetSetupScreen from './AssetSetupScreen';
import AssetFeedbackScreen from './AssetFeedbackScreen';
import AssetTroubleshootingScreen from './AssetTroubleshootingScreen';
import PinScreen from '../System/PinScreen';
import AssetOptionsScreen from './AssetOptionsScreen';
import AssetFeedbackConfirmationScreen from './AssetFeedbackConfirmationScreen';
import AssetReplaceQRScreen from './AssetReplaceQRScreen';


const Stack = createNativeStackNavigator();

export default function AssetScreen({ navigation }: { navigation: any }) {
  return (
    <Stack.Navigator>
      <Stack.Screen name="asset" component={AssetViewScreen} options={{ headerShown: false, title: 'Asset' }} />
      <Stack.Screen name="asset-options-screen" component={AssetOptionsScreen} options={{ headerShown: false, title: 'Asset Options' }} />
      <Stack.Screen name="asset-details-screen" component={AssetDetailsScreen} options={{ headerShown: false, title: 'Details' }} />
      <Stack.Screen name="asset-setup-screen" component={AssetSetupScreen} options={{ headerShown: false, title: 'Asset setup' }} />
      <Stack.Screen name="replace-qr-screen" component={AssetReplaceQRScreen} options={{ headerShown: false, title: 'Asset QR Replacement' }} />
      <Stack.Screen name="asset-feedback-screen" component={AssetFeedbackScreen} options={{ headerShown: false, title: 'Asset feedback' }} />
      <Stack.Screen name="asset-feedback-confirmation-screen" component={AssetFeedbackConfirmationScreen} options={{ headerShown: false, title: 'Confirmation' }} />
      <Stack.Screen name="asset-troubleshooting-screen" component={AssetTroubleshootingScreen} options={{ headerShown: false, title: 'Asset Troubleshooting' }} />
      <Stack.Screen name="pin-screen" component={PinScreen} options={{ headerShown: false, title: 'Admin Pin' }} />
    </Stack.Navigator>
  )
}
