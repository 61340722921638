import React, { useEffect, useState, } from 'react';
import { StyleSheet, View, Text, TouchableOpacity, ScrollView, SafeAreaView, Image } from 'react-native';
import { EvilIcons, Feather, FontAwesome } from '@expo/vector-icons';
import { assetApi, useAddAssetMutation } from '../../../../store/services/api/asset-api';
import { useGetAllAssetTypesQuery, useGetAllSubAssetTypesQuery } from '../../../../store/services/api/assetType-api';
import { useGetAllBuildingsQuery, useGetAllFloorsQuery, useGetAllLocationsQuery } from '../../../../store/services/api/building-api';
import { Controller, useForm } from 'react-hook-form';
import GlobalStyle, { PRIMARY_COLOUR } from '../../../../constants/GlobalStyle';
import { TextInput } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';
import { LoadingIndicator } from '../../../web/components/LoadingIndicator';
import * as ImagePicker from "expo-image-picker";
import { compressImage } from '../../../../utils/imageUtils';

export default function AssetSetupScreen({ navigation, route }) {
  const { uniqueRef } = route.params
  const { data: buildings } = useGetAllBuildingsQuery(null);
  const { data: floors } = useGetAllFloorsQuery(null);
  const { data: locations } = useGetAllLocationsQuery(null);
  const { data: assetTypes } = useGetAllAssetTypesQuery(null);
  const { data: subAssetTypes } = useGetAllSubAssetTypesQuery(null);
  const [createAsset, createAssetResult] = useAddAssetMutation();
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (buildings && floors && locations && assetTypes && subAssetTypes) {
      setLoading(false);
    }
  }, [buildings, floors, locations, assetTypes, subAssetTypes]);

  const onSubmit = async (data) => {
    if (selectedImage) {
      const compressedImage = await compressImage(selectedImage);
      data.ImageBase64 = compressedImage;
    }

    setLoading(true);
    var result = await createAsset(data) as any;
    setLoading(false);
    if (result.error) {
      console.log(result.error);
      return;
    }

    navigation.navigate("asset", { uniqueRef: result.data.uniqueReference });
  };


  const { control, handleSubmit, getValues, setValue, watch, formState: { errors } } = useForm({
    defaultValues: {
      id: null,
      buildingId: "",
      floorId: "",
      code: "",
      locationId: "",
      assetTypeId: "",
      subAssetTypeId: "",
      description: "",
      uniqueReference: uniqueRef,
    }
  });

  const takeImage = async () => {
    const { status } = await ImagePicker.requestCameraPermissionsAsync();
    if (status !== "granted") {
      alert("Permission to access the camera is required!");
      return;
    }

    const result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      setSelectedImage(result.assets[0].uri);
    }
  };

  const pickImage = async () => {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      alert("Permission to access the photos is required!");
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      quality: 1,
      base64: true,
      allowsMultipleSelection: true
    });

    if (!result.canceled) {
      setSelectedImage(result.assets[0].uri);
    }
  };

  const deleteImage = () => {
    setSelectedImage(null);
  };


  let buildingId = watch("buildingId");
  let floorId = watch("floorId");
  let assetTypeId = watch("assetTypeId");

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity
          style={GlobalStyle.sub__title__icon}
          onPress={() => navigation.navigate("asset", { uniqueRef: uniqueRef })}
        >
          <EvilIcons name="arrow-left" size={38} color={PRIMARY_COLOUR} />
        </TouchableOpacity>
      </View>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.section}>
          <Controller
            control={control}
            name="assetTypeId"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={{ flexDirection: 'row', }}>
                <Text style={styles.title}>Asset Type (*)</Text>
                <Picker style={styles.input}
                  selectedValue={value}
                  onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                  itemStyle={{ height: 50 }}>

                  <Picker.Item label="Please Select" value="" />

                  {assetTypes != null && assetTypes.map((value, index) => {
                    return (
                      <Picker.Item key={value.id} label={value.name} value={value.id} />
                    )
                  })}
                </Picker>
              </View>
            )}
          />
          <View style={{ justifyContent: 'flex-start', alignItems: 'flex-start', alignContent: 'flex-start' }}>
            {errors.assetTypeId && (
              <Text style={[GlobalStyle.form__input__validation, { alignSelf: 'flex-end' }]}>
                Asset Type is required.
              </Text>
            )}
          </View>
        </View>
        <View style={styles.section}>
          <Controller
            control={control}
            name="subAssetTypeId"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.title}>Sub Asset Type (*)</Text>
                <Picker style={styles.input}
                  selectedValue={value}
                  onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                  itemStyle={{ height: 50 }}>

                  <Picker.Item label="Please Select" value="" />

                  {assetTypeId != null && subAssetTypes.filter(sa => sa.assetTypeId === assetTypeId).map((value, index) => {
                    return (
                      <Picker.Item key={value.id} label={value.name} value={value.id} />
                    )
                  })}
                </Picker>
              </View>
            )}
          />
          {errors.subAssetTypeId && (
            <Text style={[GlobalStyle.form__input__validation, { alignSelf: 'flex-end' }]}>
              Sub Asset Type is required.
            </Text>
          )}
        </View>

        <View style={styles.section}>
          <Controller
            control={control}
            name="buildingId"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.title}>Building (*)</Text>
                <Picker style={styles.input}
                  selectedValue={value}
                  onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                  itemStyle={{ height: 50 }}>

                  <Picker.Item label="Please Select" value="" />

                  {buildings != null && buildings.map((value, index) => {
                    return (
                      <Picker.Item key={value.id} label={value.name} value={value.id} />
                    )
                  })}
                </Picker>
              </View>
            )}
          />
          {errors.buildingId && (
            <Text style={[GlobalStyle.form__input__validation, { alignSelf: 'flex-end' }]}>
              Building is required.
            </Text>
          )}
        </View>
        <View style={styles.section}>
          <Controller
            control={control}
            name="floorId"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.title}>Floor (*)</Text>
                <Picker style={styles.input}
                  selectedValue={value}
                  onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                  itemStyle={{ height: 50 }}>

                  <Picker.Item label="Please Select" value="" />

                  {buildingId && floors.filter(f => f.buildingId === buildingId).map((value, index) => {
                    return (
                      <Picker.Item key={value.id} label={value.name} value={value.id} />
                    )
                  })}
                </Picker>
              </View>
            )}
          />
          {errors.floorId && (
            <Text style={[GlobalStyle.form__input__validation, { alignSelf: 'flex-end' }]}>
              Floor is required.
            </Text>
          )}
        </View>
        <View style={styles.section}>
          <Controller
            control={control}
            name="locationId"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.title}>Location (*)</Text>
                <Picker style={styles.input}
                  selectedValue={value}
                  onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                  itemStyle={{ height: 50 }}>

                  <Picker.Item label="Please Select" value="" />

                  {floorId && locations.filter(l => l.floorId === floorId).map((value, index) => {
                    return (
                      <Picker.Item key={value.id} label={value.name} value={value.id} />
                    )
                  })}
                </Picker>
              </View>
            )}
          />
          {errors.locationId && (
            <Text style={[GlobalStyle.form__input__validation, { alignSelf: 'flex-end' }]}>
              Location is required.
            </Text>
          )}
        </View>
        <View style={styles.section}>
          <Controller
            control={control}
            name="code"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.title}>Code </Text>
                <TextInput
                  style={styles.input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}>
                </TextInput>
              </View>
            )}
          />
        </View>
        <View style={styles.section}>
          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.title}>Description</Text>
                <TextInput
                  style={styles.input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  multiline
                  numberOfLines={1}>
                </TextInput>
              </View>
            )}
          />
        </View>
        <View style={styles.section}>
          <Controller
            control={control}
            name="uniqueReference"
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={{ flexDirection: 'row' }}>
                <Text style={styles.title}>CoreRFID QR</Text>
                <TextInput
                  style={styles.input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  multiline
                  numberOfLines={1}>
                </TextInput>
              </View>
            )}
          />
        </View>
        <View style={styles.section}>
          <View style={styles.imagesContainer}>
            {selectedImage === null && (
              <TouchableOpacity onPress={pickImage} style={styles.addButton}>
                <FontAwesome name="file-photo-o" size={24} color="white" />
              </TouchableOpacity>
            )}
            {selectedImage === null && (
              <TouchableOpacity onPress={takeImage} style={styles.addButton}>
                <Feather name="camera" size={24} color="white" />
              </TouchableOpacity>
            )}

            <View
              style={styles.imageScroll}
            >
              <TouchableOpacity
                key={selectedImage}
                onPress={() => deleteImage()}
                style={styles.imageWrapper}
              >
                <Image source={selectedImage} style={styles.image} resizeMode={"contain"} />
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.buttonContainer}>
            <TouchableOpacity
              style={styles.button}
              onPress={handleSubmit(onSubmit)}
            >
              <Text style={styles.buttonText}>Submit asset</Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white'
  },
  scrollViewContent: {
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center'
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 10,
    alignSelf: "stretch",
  },
  title: {
    flex: 1,
    fontSize: 14,
    fontWeight: 'bold',
    marginVertical: 20,
    textAlign: 'center',
  },
  buttonContainer: {
    marginVertical: 10,
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    alignSelf: 'center'
  },
  button: {
    backgroundColor: PRIMARY_COLOUR,
    padding: 8,
    borderRadius: 4,
    justifyContent: "center",
    width: '90%',
    alignSelf: 'center'
  },
  buttonText: {
    color: 'white',
    marginLeft: 10,
    alignSelf: 'center',
    fontSize: 20
  },
  footer: {
    fontSize: 16,
    color: '#3B3B3B',
    marginBottom: 20,
  },
  section: {
    width: "90%",
    height: "auto",
    alignItems: 'stretch',
    justifyContent: 'space-evenly'
  },
  input: {
    flex: 2,
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 5,
    backgroundColor: "white",
    width: "100%",
    margin: 5,
  },
  imagesContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 5,
    marginHorizontal: 10
  },
  addButton: {
    marginRight: 10,
    backgroundColor: PRIMARY_COLOUR,
    padding: 8,
    borderRadius: 4,
    justifyContent: "center",
  },
  imageScroll: {
    flexDirection: "row",
    marginLeft: 10
  },
  imageWrapper: {
    marginRight: 10,
  },
  image: {
    width: 60,
    height: 60,
    borderRadius: 8,
  },
});
