import { useEffect, useState } from "react";
import { View, StyleSheet, TouchableOpacity, ScrollView, Text, TextInput } from "react-native";
import GlobalStyle, { PRIMARY_COLOUR } from "../../../../constants/GlobalStyle";
import PaginationComponent from "../../components/PaginationComponent";
import { DataTable } from "react-native-paper";
import { Ionicons, Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import { buildingApi, useCreateBuildingMutation, useDeleteBuildingMutation, useUpdateBuildingMutation } from "../../../../store/services/api/building-api";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { PagedTableDto } from "../../../../store/services/api/base-api";
import Spinner from "react-native-loading-spinner-overlay";
import { DataTableHeader } from "../../components/DataTableHeader";
import DataTableCell from "../../components/DataTableCell";

export default function BuildingsListScreen({ onSelected, navigation }) {
    const [selectedBuilding, setSelectedBuilding] = useState<any>(null);

    const [page, setPage] = useState<number>(0);
    const [sortedColumn, setSortedColumn] = React.useState<string>("name");
    const [sortedColumnAscending, setSortedColumnAscending] = React.useState<boolean>(true);
    const [numberOfItemsPerPage, setNumberOfItemsPerPage] = useState<any>(10);
    const [loading, setLoading] = useState<any>(false);

    const [trigger, buildingsResult] = buildingApi.useLazyGetBuildingsQuery();
    const [createBuilding, createBuildingResult] = useCreateBuildingMutation();
    const [updateBuilding, updateBuildingResult] = useUpdateBuildingMutation();
    const [deleteBuilding, deleteBuildingResult] = useDeleteBuildingMutation();

    const { control, handleSubmit, getValues, setValue, reset, watch, formState: { errors } } = useForm({
        defaultValues: {
            id: null,
            name: ""
        }
    });

    const id = watch("id");

    useEffect(() => {
        loadTable();
    }, [page, sortedColumn, sortedColumnAscending, numberOfItemsPerPage]);

    useEffect(() => {
        onSelected(selectedBuilding);
    }, [selectedBuilding])

    const loadTable = () => {
        trigger(new PagedTableDto(page, { pageSize: numberOfItemsPerPage }, sortedColumn, sortedColumnAscending))
            .then(() => {
                setLoading(false);
            });
    }

    const edit = (building) => {
        setValue("id", building.id);
        setValue("name", building.name);
    }

    const remove = async (building) => {
        if (confirm("Are you sure you want to delete this building?")) {
            if (building.assetCount !== 0) {
                alert(`Can't delete building, there are ${building.assetCount} assets associated with it`)
                return;
            }
            var result = await deleteBuilding(building.id) as any;

            if (result.error) {
                alert("Error deleting building. Please try again.")
                return;
            }
            setSelectedBuilding(null);
            loadTable();
        }
    }

    const toggleBuilding = (building) => {
        if (selectedBuilding != null && selectedBuilding.id == building.id) {
            setSelectedBuilding(null);
        } else {
            setSelectedBuilding(building);
        }

    }

    const onSubmit = async (data) => {
        var result = null;

        if (data.id != null) {
            result = await updateBuilding(data) as any;
        } else {
            result = await createBuilding(data) as any;
        }

        if (result.error) {
            alert(result.error)
            return;
        }

        reset();
        loadTable();
    }

    return (
        <ScrollView>
            <Spinner
                visible={loading}
                textContent={'Loading...'}
                textStyle={GlobalStyle.spinnerTextStyle}
            />
            <View>
                <Text style={[GlobalStyle.add__new__link, styles.add__new__link]}>Buildings</Text>
                <View style={[GlobalStyle.form, styles.form]}>
                    <View style={GlobalStyle.form__row}>
                        <View style={[GlobalStyle.form__column, { maxWidth: selectedBuilding ? "60%" : "33.3%" }]}>
                            <Controller
                                control={control}
                                rules={{ required: true }}
                                name="name"
                                render={({ field: { onChange, onBlur, value } }) => (
                                    <View>
                                        <Text style={GlobalStyle.form__column__text}>Name (*)</Text>
                                        <TextInput
                                            style={[GlobalStyle.form__column__input]}
                                            onBlur={onBlur}
                                            onChangeText={onChange}
                                            value={value}>
                                        </TextInput>
                                    </View>
                                )}
                            />

                            {errors.name && <Text style={GlobalStyle.form__input__validation}>Building name is required.</Text>}
                        </View>

                        <View style={styles.form__buttons}>
                            <TouchableOpacity style={styles.form__add__btn} onPress={handleSubmit(onSubmit)}>
                                <Text style={styles.form__add__btn__text}>{id == null ? 'Add' : 'Update'}</Text>
                            </TouchableOpacity>

                            {id != null &&
                                <TouchableOpacity style={styles.form__add__btn} onPress={() => reset()}>
                                    <Text style={styles.form__add__btn__text}>Clear</Text>
                                </TouchableOpacity>
                            }
                        </View>
                    </View>
                </View>
            </View>

            <View>
                <DataTable style={GlobalStyle.table}>

                    <DataTableHeader
                        sortedColumn={sortedColumn}
                        sortedColumnAscending={sortedColumnAscending}
                        onSorted={(column, ascending) => { setSortedColumn(column); setSortedColumnAscending(ascending); }}
                        headers={[
                            { text: 'Name', sortedName: "name", visible: true },
                            { text: 'Actions', visible: true },
                            { text: 'Floors', visible: true },
                        ]}
                    />

                    {buildingsResult?.data?.list.map((element: any) => {
                        return (
                            <DataTable.Row key={element.id}>
                                <DataTableCell>{element.name}</DataTableCell>
                                <DataTableCell>
                                    <TouchableOpacity onPress={() => edit(element)}>
                                        <Entypo name="pencil" size={24} color="black" />
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => remove(element)}>
                                        <Ionicons name="ios-remove-circle" size={24} color="red" />
                                    </TouchableOpacity>
                                </DataTableCell>
                                <DataTableCell>
                                    <TouchableOpacity onPress={() => toggleBuilding(element)}>
                                        <MaterialCommunityIcons name="view-list-outline" size={24} color="black" />
                                    </TouchableOpacity>
                                </DataTableCell>
                            </DataTable.Row>
                        )
                    })}
                </DataTable>

                <PaginationComponent
                    numberOfItemsPerPage={numberOfItemsPerPage}
                    setNumberOfItemsPerPage={setNumberOfItemsPerPage}
                    page={page}
                    setPage={setPage}
                    fetchResult={buildingsResult}
                    setLoading={setLoading}
                />
            </View>


        </ScrollView>
    )
}

const styles = StyleSheet.create({
    building__content: {
        display: 'flex',
        flexDirection: 'column',
        padding: 10
    },

    building__block: {
        flex: 1,
        padding: 10,
        backgroundColor: '#FFF',
        margin: 10,
        borderWidth: 1,
        borderColor: '#CECECE'
    },

    add__new__link: {
        borderBottomColor: '#CECECE',
        borderBottomWidth: 1,
        paddingBottom: 10,
        marginBottom: 0,
    },

    form: {
        borderBottomWidth: 1,
        borderColor: '#CECECE',
        paddingBottom: 20,
        marginBottom: 10
    },

    form__buttons: {
        width: 150,
        flexDirection: 'row',
        alignItems: 'center'
    },

    form__add__btn: {
        backgroundColor: PRIMARY_COLOUR,
        flex: 1,
        height: 31,
        alignSelf: 'flex-end',
        textAlign: 'center',
        marginLeft: 10,
        marginBottom: 5,

        alignItems: 'center',
        justifyContent: 'center',
    },

    form__add__btn__text: {
        color: '#FFF',
    },

    table__actions: {
        flexDirection: 'row',
    },

    action: {
        paddingLeft: 5,
        paddingRight: 5,
    },

    building__header: {
        flex: 1,
        flexDirection: 'row',
        padding: 10,
        backgroundColor: '#FFF',
        margin: 10,
        borderWidth: 1,
        borderColor: '#CECECE'
    },
    building__filter__column: {
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        flexWrap: 'wrap',
        maxWidth: "33.5%"
    },
});