import React, { useState, useEffect } from 'react';
import { Modal, View, StyleSheet, TouchableOpacity, Text, TextInput } from 'react-native';
import { DataTable } from 'react-native-paper';
import { ticketApi } from "../../../../store/services/api/ticket-api";
import { Entypo, SimpleLineIcons } from "@expo/vector-icons";
import { useGetTicketsByAssetQuery } from '../../../store/services/api/ticket-api';
import GlobalStyle from '../../../constants/GlobalStyle';
import DataTableCell from './DataTableCell';
import { DataTableHeader } from './DataTableHeader';
import { Picker } from '@react-native-picker/picker';

const AssetTicketModal = ({ assetId, onClose }) => {
  const [ticketNumber, setTicketNumber] = useState('');
  const [status, setStatus] = useState('All');
  const [filteredTickets, setFilteredTickets] = useState([]);

  const { data: tickets, isError, refetch } = useGetTicketsByAssetQuery(assetId);

  useEffect(() => {
    if (tickets) {
      filterTickets();
    }
  }, [tickets]);

  const filterTickets = () => {
    let filtered = tickets;
    if (ticketNumber) {
      filtered = filtered.filter(ticket => ticket.number.toString().toLowerCase().includes(ticketNumber.toLowerCase()));
    }
    if (status !== 'All') {
      filtered = filtered.filter(ticket => ticket.status === status);
    }

    console.log(filtered);

    setFilteredTickets(filtered);
  };

  const resetFilters = () => {
    setTicketNumber('');
    setStatus('All');
  };

  return (
    <Modal
      animationType="none"
      transparent={true}
      visible={true}
      onRequestClose={onClose}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <TouchableOpacity style={styles.closeButton} onPress={onClose}>
            <Entypo name="cross" size={24} color="black" />
          </TouchableOpacity>
          <Text style={styles.modalTitle}>Asset Tickets</Text>

          <View style={[GlobalStyle.filters, { width: '100%' }]}>
            <View style={GlobalStyle.filters__header}>
              <Text style={GlobalStyle.filters__header__text}>Search Filters</Text>
            </View>

            <View style={GlobalStyle.filter__columns}>
              <View style={GlobalStyle.column__flex}>
                <Text style={GlobalStyle.column__header}>Ticket Number:</Text>
                <TextInput
                  style={[GlobalStyle.column, GlobalStyle.column__input]}
                  value={ticketNumber}
                  onChangeText={setTicketNumber}
                  placeholder="Enter ticket number"
                />
              </View>

              <View style={GlobalStyle.column__flex}>
                <Text style={GlobalStyle.column__header}>Status</Text>
                <Picker
                  style={[GlobalStyle.column, GlobalStyle.column__input]}
                  selectedValue={status}
                  onValueChange={(itemValue) => setStatus(itemValue)}
                >
                  <Picker.Item label="All" value="All" />
                  <Picker.Item label="Open" value="Open" />
                  <Picker.Item label="Closed" value="Closed" />
                </Picker>
              </View>

              <View style={GlobalStyle.column__flex}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <TouchableOpacity style={GlobalStyle.filter__search__button} onPress={() => filterTickets()}>
                    <SimpleLineIcons name="magnifier" size={16} color="white" />
                  </TouchableOpacity>

                  <TouchableOpacity style={GlobalStyle.filter__clear__button} onPress={() => resetFilters()}>
                    <SimpleLineIcons name="ban" size={16} color="black" />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>

          <DataTable style={GlobalStyle.table}>
            <DataTableHeader
              headers={[
                { text: 'Ticket Number', sortedName: "number", visible: true, flex: 0.5 },
                { text: 'Date Submitted', sortedName: "dateSubmittedFull", visible: true },
                { text: 'Ticket Description', sortedName: "description", visible: true },
                { text: 'Contact Email', sortedName: "contactEmail", visible: true },
                { text: 'Status', sortedName: "status", visible: true, flex: 0.5 },
                { text: 'Date of Status Change', sortedName: "dateofStatusChangeFull", visible: true },
              ]}
            />

            {filteredTickets?.map((ticket) => (
              <DataTable.Row key={ticket.number}>
                <DataTableCell width={0.5}>{ticket.number}</DataTableCell>
                <DataTableCell>{ticket.dateSubmitted}</DataTableCell>
                <DataTableCell>{ticket.description}</DataTableCell>
                <DataTableCell>{ticket.contactEmail}</DataTableCell>
                <DataTableCell width={0.5}>{ticket.status}</DataTableCell>
                <DataTableCell>{ticket.dateClosed}</DataTableCell>
              </DataTable.Row>
            ))}
          </DataTable>

          {filteredTickets?.length === 0 && <Text>No tickets found for this asset.</Text>}
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  modalView: {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '90%',
    maxHeight: '90%',
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
  },
});

export default AssetTicketModal;