import { baseApi, PagedTableDto } from "./base-api";

export const feedbackApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        exportFeedback: build.query<any, PagedTableDto>({
            query: (dto) => ({
              url: `feedback/exportfeedback?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
            }),
        }),
        getFeedback: build.query<any, any>({
            query: (dto) => ({
                url: `feedback/getfeedback?pageNumber=${dto.page + 1}&${dto.getFilters()}`,
            }),
            transformResponse: (response: any, meta, arg) => {
                var pagination = JSON.parse(meta.response.headers.get("X-Pagination"));

                return {
                    list: response,
                    pagination: pagination
                };
            }
        }),
        addFeedback: build.mutation<any, any>({
            query: (body) => ({
                url: "feedback/addFeedback",
                method: "post",
                body: body,
            }),
        }),
        getAverageRating: build.query<any, any>({
            query: (assetId) => ({
                url: `feedback/getAverageRating/${assetId}`,
            }),
        }),
    }),
    overrideExisting: true,
});

export const { useAddFeedbackMutation, useGetAverageRatingQuery, useGetFeedbackQuery, useExportFeedbackQuery } = feedbackApi;
